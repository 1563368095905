<script setup lang="ts">
   import { storeToRefs } from 'pinia'
   import { useAppStore } from '~/stores/app'
   import { useAuthStore } from '~/stores/auth'
   import { usePageStore } from '~/stores/page';
   import { useUserStore } from '~/stores/user';

   const appStore = useAppStore()
   const authStore = useAuthStore()
   const { isMobile, drawer, drawerRail } = storeToRefs(appStore)

   const userStore = useUserStore()

   const { table } = storeToRefs(usePageStore())
   const pages = computed(() => table.value.filter(page => page.roles.some(role => role === userStore.current.role_id)))
</script>

<template>
   <v-navigation-drawer
      v-if="authStore.isAuthenticated && (authStore.isAdmin || userStore.current.craft_id)"
      v-model="drawer"
      width="200"
      app
      expand-on-hover
      :rail="drawerRail"
      location="left"
      :class="isMobile ? '' : 'pt-14 fill-height'">
      <v-list>
         <div v-for="(page, i) in pages">
            <v-list-item v-if="page.id > 0" :key="i" variant="text" :value="page.id" :to="page.path">
               <template #prepend>
                  <v-icon :icon="page.icon" />
               </template>
               <v-list-item-title class="text-body-2">{{ page.name }}</v-list-item-title>
            </v-list-item>
            <div v-else class="my-8"></div>
         </div>
      </v-list>
   </v-navigation-drawer>
</template>
