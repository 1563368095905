<script setup lang="ts">
   import { mergeProps } from 'vue'
   import { useAuthStore } from '~/stores/auth'
   import { useUserStore } from '~/stores/user'

   const authStore = useAuthStore()
   const userStore = useUserStore()
   
   const profileMenu = ref<boolean>(false)
   
   function closeMenu() {
      profileMenu.value = false
   }

   function logout() {
      closeMenu()
      authStore.logout()
   }
</script>

<template>
   <v-menu v-model="profileMenu" :close-on-content-click="false" location="bottom">
      <template #activator="{ props: tooltip }">
         <v-btn icon="mdi-account" color="white" v-bind="tooltip" />
      </template>

      <v-card v-if="userStore.current && userStore.current.id" class="mt-2 mr-n3" style="min-width: 256px;">
         <div class="d-flex align-center">
            <v-icon class="ma-2" color="brand-grey" icon="mdi-account-circle" size="x-large" />
            <v-list-item class="px-0 pb-2" :title="`${userStore.current.first_name} ${userStore.current.last_name}`" :subtitle="userStore.current.role" />
         </div>

         <v-divider />

         <v-col class="bg-grey-lighten-4">
            <v-row density="compact" class="d-flex justify-space-between ma-0 ga-6">
               <span>Member/Card #:</span><span>{{ userStore.current.member_id ? userStore.current.member_id : '—' }}</span>
            </v-row>
            <v-row density="compact" class="d-flex justify-space-between ma-0 mt-2 ga-6">
               <span>Local:</span><span>{{ userStore.current.local ? userStore.current.local.local_number : '—' }}</span>
            </v-row>
         </v-col>

         <v-divider />

         <v-btn class="w-100" variant="text" color="warning" @click="logout">Logout</v-btn>
      </v-card>
      <v-card v-else>
         <p class="pa-3 text-center">You are not logged in.</p>
         <v-btn class="w-100" variant="text" color="warning" @click="logout">Login</v-btn>
      </v-card>
   </v-menu>
</template>
