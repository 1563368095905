<script setup lang="ts">
   import { useAppStore } from '~/stores/app'
   import { useNotificationStore } from '~/stores/notification'
   import { useUserStore } from '~/stores/user'
   import { normalDate } from '~/utils/helpers'
   import { formRules } from '~/constants/app'
   import { numbersOnly, formatAsPhone, formatAsPhoneWithReturn } from '~/utils/helpers'
   
   const appStore = useAppStore()
   appStore.notificationsDrawer = true
   const { refreshKey } = storeToRefs(appStore)

   const userStore = useUserStore()
   const isPending = ref<boolean>(false)

   const notificationStore = useNotificationStore()
   const { notifications, method, methods, isPending: isPending_notify } = storeToRefs(notificationStore)
   const { fetchAll, setMethod, dismiss, getMethods } = notificationStore

   const showSettings = ref<boolean>(false)
   const email = ref<string>(userStore.current.email)
   const phone = ref<string>(userStore.current.phone)

   const form = ref()

   async function save(): Promise<void> {
      const { valid } = await form.value!.validate()
      if (!valid) return

      if (method.value === 1 || method.value === 4) {
         phone.value = userStore.current.phone
      }
      
      if (method.value === 2 || method.value === 4) {
         email.value = userStore.current.email
      }

      const success = await setMethod(email.value, phone.value)

      if (!success) return

      showSettings.value = false
      await userStore.fetchUser()
   }

   async function dismissNotification(id: number): Promise<void> {
      const success = await dismiss(id)
      if (success) {
         const found = notifications.value.find(n => n.id === id)
         if (found) {
            const index = notifications.value.indexOf(found)
            notifications.value.splice(index, 1)
         }
      }
   }

   function goToPage(path: string, target_user_id: number, item_id: number, item_type_id: number): void {
      console.log('goToPage()', path, target_user_id, item_id)
      isPending.value = true
      
      let pathName = path
      if (userStore.current.role_id === 3 && path === 'hours-logs') {
         pathName = 'index'
         path = ''
      }

      appStore.setRefreshData(path, target_user_id, item_id, item_type_id === 5 || item_type_id === 6 ? 2 : 1)
      
      if (pathName === useRoute().name) {
         refreshKey.value = refreshKey.value + 1
         appStore.rightDrawer = false
      }
      else {
         navigateTo(`./${path}`)
      }
      isPending.value = false
   }

   async function init(): Promise<void> {
      phone.value = formatAsPhoneWithReturn(phone.value)
      if (!userStore.current || !userStore.current.id) return

      await getMethods()
      await fetchAll()
      method.value = userStore.current.method
      for (let i = 0; i < notifications.value.length; i++) {
         notifications.value[i].showMessage = false
      }
   }

   init()
</script>

<template>
   <v-navigation-drawer v-model="appStore.rightDrawer" temporary width="400" location="right">
      <v-card variant="flat">
         <v-row class="ma-0 align-center">
            <v-card-title>Notifications</v-card-title>
            <v-spacer />
            <v-btn
               color="primary"
               variant="text"
               
               :disabled="isPending_notify"
               @click="showSettings = !showSettings">
               <v-icon
                  icon="mdi-cog-outline"
                  style="transition: 0.250s ease-out;"
                  :style="showSettings ? 'rotate: -90deg;' : 'rotate: 0deg;'" />
               Settings
            </v-btn>
         </v-row>

         <v-divider />

         <v-expand-transition>
            <v-form v-show="showSettings" class="px-3" ref="form">
               <v-select
                  label="Notification Method"
                  class="pt-4"
                  v-model="method"
                  :items="methods"
                  item-title="method"
                  item-value="id"
                  variant="outlined"
                  density="compact"
                  :disabled="isPending_notify" />
               <v-text-field
                  v-if="method === 1 || method === 3"
                  label="E-mail Address"
                  class="mb-3"
                  v-model="email"
                  item-title="name"
                  item-value="id"
                  variant="underlined"
                  density="compact"
                  hide-details="auto"
                  prepend-icon="mdi-email-outline"
                  :rules="formRules.email"
                  :disabled="isPending_notify" />
               <v-text-field
                  v-if="method === 2 || method === 3"
                  label="Phone Number (coming soon)"
                  v-model="phone"
                  item-title="name"
                  item-value="id"
                  validate-on="submit"
                  variant="underlined"
                  density="compact"
                  maxlength="14"
                  hide-details="auto"
                  prepend-icon="mdi-phone"
                  :rules="formRules.phoneReq"
                  :disabled="isPending_notify || true"
                  @keydown="(evt) => numbersOnly(evt)"
                  @keyup="(evt) => formatAsPhone(evt)" />
                  <p v-if="method === 2 || method === 3"
                     class="text-caption pl-10" style="opacity: 0.5;">Message and data rates may apply.</p>
               <v-card-actions class="mb-6">
                  <v-spacer />
                     <v-btn
                        variant="elevated"
                        color="primary"
                        :disabled="isPending_notify"
                        @click="save">
                        <v-icon icon="mdi-floppy" class="pr-1" />
                        Save
                     </v-btn>
               </v-card-actions>
            </v-form>
         </v-expand-transition>
         
         <v-list :disabled="isPending || isPending_notify">
            <v-list-item v-for="(n, i) in notifications" :key="i" class="px-2">
               <div class="d-flex align-center ga-1">
                  <v-btn v-if="n.type_id === 7"
                     class="text-body-2 px-2"
                     style="justify-content: start; flex-grow: 1;"
                     :color="n.color"
                     variant="text"
                     :prepend-icon="n.icon"
                     @click="n.showMessage = !n.showMessage">
                     {{ n.type }}
                  </v-btn>
                  <v-btn v-else
                     @click="goToPage(n.page_path, n.target_user_id, n.page_id, n.type_id)"
                     class="text-body-2 px-2"
                     style="justify-content: start; flex-grow: 1;"
                     :color="n.color"
                     variant="text"
                     :prepend-icon="n.icon">
                     <u v-if="n.color === 'warning'">Reminder</u><u v-else>Notice</u>: {{ n.type }}
                  </v-btn>
                  <p class="text-subtitle-2">{{ normalDate(n.creation_date) }}</p>
                  <!--<p class="text-subtitle-2">{{ normalDate(n.update_date ? n.update_date : n.creation_date) }}</p>-->
                  <v-btn icon variant="icon" color="error" size="small" @click="dismissNotification(n.id)">
                     <v-icon color="error" icon="mdi-delete" />
                  </v-btn>
               </div>
               <v-card class="text-subtitle-2 ml-8 mt-n1" variant="text" style="opacity: 0.6;"><span v-if="userStore.current.role_id < 3">{{ n.username }}: </span>{{ n.period_name }}</v-card>
               <v-expand-transition>
                  <div v-show="n.showMessage">
                     <div class="text-subtitle-2 pl-8 pr-3" style="opacity: 0.8;">{{ n.message }}</div>
                  </div>
               </v-expand-transition>
               <v-divider v-if="i < notifications.length - 1" class="mt-2" />
            </v-list-item>
            <v-list-item v-if="notifications.length === 0"
               subtitle="There are no notifications available at this time."
               prepend-icon="mdi-check" />
         </v-list>

         <v-card-actions>
            <v-spacer />
            <v-btn variant="text" color="warning" @click.stop="appStore.rightDrawer = !appStore.rightDrawer">Close</v-btn>
            <v-spacer />
         </v-card-actions>
      </v-card>
   </v-navigation-drawer>
</template>
