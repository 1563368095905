<script setup>
   import { storeToRefs } from 'pinia'
   import { useAppStore } from '~/stores/app'
   import { useNotificationStore } from '~/stores/notification'
   import { useRoleStore } from '~/stores/role'
   import { useUserStore } from '~/stores/user'

   const appStore = useAppStore()
   const { isLoading, drawer, isMobile } = storeToRefs(appStore)
   const { toggleRightDrawer } = appStore
   const { notifications } = storeToRefs(useNotificationStore())
   const roleStore = useRoleStore()
   const userStore = useUserStore()

   const topPadding = computed(() => {
      return !isMobile.value && !drawer.value
   })

   async function init() {
      await roleStore.fetchRoles()
      isLoading.value = false
   }

   onBeforeMount(() => {
      drawer.value = null
   })

   onMounted(() => {
      init()
   })
</script>

<template>
   <v-layout full-height class="app-layout">
      <!-- APP BAR -->
      <v-app-bar :height="$vuetify.display.mobile ? '50' : '64'" class="appbar">

         <v-tooltip v-if="$vuetify.display.mobile" location="bottom">
            <template #activator="{ props }">
               <v-app-bar-nav-icon color="white" v-bind="props" @click.stop="drawer = !drawer" />
            </template>
            Menu
         </v-tooltip>

         <GlobalAppLogo :appbar="true" :class="$vuetify.display.mobile ? 'px-2' : 'pl-6'" />

         <!-- SITE TITLE -->
         <p class="pl-0 pr-1 text-white text-uppercase cursor-default"
            :class="$vuetify.display.mobile ? 'text-subtitle-1' : 'text-h6'"
            style="opacity: 0.7">{{ appStore.siteName }}
         </p>

         <v-spacer />

         <!-- Notifications MENU BTN -->
         <v-btn v-if="userStore.current && userStore.current.id"
            class="mr-2"
            icon
            color="white"
            @click.stop="toggleRightDrawer()">
            <v-badge v-if="notifications && notifications.length > 0"
               color="error"
               :content="notifications.length">
               <v-icon
                  color="white"
                  icon="mdi-bell-outline" />
            </v-badge>
            <v-icon v-else
               color="white"
               icon="mdi-bell-outline" />
         </v-btn>

         <!-- PROFILE MENU -->
         <GlobalAppProfileMenu />

      </v-app-bar>
      <GlobalAppNotificationsDrawer ref="notificationDrawer" />

      <!-- NAVIGATION MENU -->
      <GlobalAppNavDrawer />

      <v-main class="fill-height" :class="isMobile ? 'pt-8' : ''">
         <slot></slot>
      </v-main>

      <GlobalAppFooter />
      <GlobalAppNotification />
      <GlobalInterpageWarning />
   </v-layout>
</template>

<style lang="scss">
   .dark-bg {
      background-color: $dark-background !important;
   }
   .light-bg {
      background-color: $light-background !important;
   }
   .drawer-bg {
      background-color: $light-background !important;
      background: linear-gradient(rgba(var(--v-theme-accent), 1), rgba(var(--v-theme-accent), 1), rgba(var(--v-theme-accent), 1), rgba(var(--v-theme-accent), 0)) !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
   }
   .drawer-bg-dark {
      background: linear-gradient(rgba(var(--v-theme-brand-grey), 1), rgba(var(--v-theme-brand-grey), 1), rgba(var(--v-theme-brand-grey), 1), rgba(var(--v-theme-brand-grey), 0)) !important;
      background-repeat: no-repeat;
      background-size: cover;
   }
   .appbar {
      background-color: $brand-primary !important;
      overflow-y: visible !important;
      overflow-x: visible !important;
   }
   .appbar-bottom {
      background-color: $brand-secondary !important;
      width: 100%;
      height: 8px;
      position: absolute;
      bottom: 0;
   }
   .appbar-bottom-mini {
      height: 4px;
   }
   /* Breadcrumbs */
   ul.breadcrumbs {
      padding-left: 0;
   }
   .breadcrumbs {
      display: inline-flex;
      padding: 0 !important;
   }
   .breadcrumbs-btn {
      opacity: 0.6 !important;
      min-width: auto !important;
   }
   .breadcrumbs-btn:hover {
      opacity: 1 !important;
   }
   .breadcrumbs-btn-disabled {
      opacity: 1 !important;
      pointer-events: none !important;
      cursor: default;
   }
   .breadcrumb-divider {
      font-size: 0.9rem !important;
      opacity: 0.6 !important;
   }

   /* Tabs */
   :deep(.v-tabs--icons-and-text > .v-tabs-bar) {
      height: auto;
   }
   :deep(.v-tabs--icons-and-text > .v-tabs-bar .v-tab) {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: row-reverse;
   }
   .v-toolbar-title {
      min-width: auto;
   }
   .v-toolbar-title__placeholder {
      display: flex;
   }

   // List Items
   .list-titles {
      color: #666;
   }
</style>
